<template>
  <div class="Tender">
    <!-- Page header -->
    <div class="PageHeader">
      <Button
        :text="mixWB('BACK')"
        class="Back"
        :path="{ name: 'ResultOverview', params: { screeningID: screeningID } }" />
    </div>

    <MissingThings />

    <div class="PageList">
      <FrontPage/>
      <TableOfContents
        :allPagesLoaded="allPagesLoaded"
        :pdfType="'tender'" />
      <GeneralInformation />
      <UnitPage />
      <MakeAnOffer />
      <AnalysisResults :pdf-type="'tender'" />
      <CategoriesAndTypes :pdf-type="'tender'" />
    </div>
  </div>
</template>

<script>
import Button from '@/components/Buttons/Button.vue'
import FrontPage from '@/components/PDF/Tender/FrontPage.vue'
import TableOfContents from '@/components/PDF/TableOfContents.vue'
import GeneralInformation from '@/components/PDF/Tender/GeneralInformation.vue'
import UnitPage from '@/components/PDF/Tender/UnitPage.vue'
import MakeAnOffer from '@/components/PDF/Tender/MakeAnOffer.vue'
import AnalysisResults from '@/components/PDF/AnalysisResults/AnalysisResults.vue'
import CategoriesAndTypes from '@/components/PDF/CategoriesAndTypes/CategoriesAndTypes.vue'
import MissingThings from '@/components/PDF/Tender/MissingThings.vue'
import EventBus from '@/EventBus'

export default {
  name: 'Tender',
  data() {
    return {
      pagesToBeLoaded: {
        makeAnOffer: false,
        analysisOverview: false,
        categoriesAndTypes: false,
      },
    }
  },
  computed: {
    allPagesLoaded() {
      const anyPageNotLoaded = Object.keys(this.pagesToBeLoaded).find(
        (key) => !this.pagesToBeLoaded[key],
      )

      return !anyPageNotLoaded
    },
    screeningID() {
      return this.$route.params.screeningID
    },
  },
  methods: {
    onPageLoaded(pageName) {
      this.pagesToBeLoaded[pageName] = true
    },
  },
  components: {
    Button,
    FrontPage,
    TableOfContents,
    GeneralInformation,
    UnitPage,
    MakeAnOffer,
    AnalysisResults,
    CategoriesAndTypes,
    MissingThings,
  },
  created() {
    EventBus.$on('page-loaded', this.onPageLoaded)
  },
  destroyed() {
    EventBus.$off('page-loaded', this.onPageLoaded)
  },
}
</script>

<style lang="stylus" scoped>
  .Tender
    display block

  .PageHeader
    width 794px
    margin 20px auto
    .Back
      display inline-block
      width 100px

  @media print
    .PageHeader
      display none
</style>

<style lang="stylus">
  .PageTender
    background-color $color_grey_lighter

  .PageList
    *
      font-family 'Montserrat', sans-serif
      font-size 0.75rem
      line-height 1.5
    span
      display block
</style>

